<script>
import { Bar } from 'vue-chartjs';
import { mapState } from 'vuex';
import { getFormattedCurrentDate } from '@/utils.js';

export default {
  extends: Bar,
  props: {
    datasets: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chartdata: {},
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          mode: 'index',
          intersect: false
        },
        hover: {
          mode: 'index',
          intersect: false,
          animationDuration: 0
        },
        scales: {
          yAxes: [
            {
              id: 'y-axis-1',
              position: 'left',
              ticks: {
                display: true
              },
              scaleLabel: {
                display: true,
                labelString: this.datasets[0].label
              }
            },
            {
              id: 'y-axis-2',
              display: this.datasets.length > 1,
              position: 'right',
              ticks: {
                display: true
              },
              scaleLabel: {
                display: true,
                labelString: this.datasets.length > 1 ? this.datasets[1].label : ''
              },
              gridLines: {
                drawTicks: false,
                display: false
              }
            }
          ],
          xAxes: [
            {
              id: 'x-axis-1',
              ticks: {
                display: true
              },
              gridLines: {
                drawTicks: true,
                display: true
              }
            }
          ]
        }
      }
    };
  },
  computed: {
    ...mapState(['table_chart'])
  },
  mounted() {
    this.chartdata = {
      labels: this.table_chart.rasterVectorChartLabels.map(time => {
        const timeFormat = time.includes('T') ? getFormattedCurrentDate(new Date(time)) : time.replace('Z', '');

        return timeFormat.replace(/-/g, '/');
      }),
      datasets: this.table_chart.rasterVectorChartDataSetBar.map((data, index) => {
        return {
          ...data,
          yAxisID: index == 0 ? 'y-axis-1' : 'y-axis-2'
        };
      })
    };
    this.$emit('chart_data', this.chartdata);
    this.separateDaysPlugin();
    this.renderChart(this.chartdata, this.options);
  },
  methods: {
    separateDaysPlugin() {
      this.addPlugin({
        beforeDatasetsDraw: chart => {
          const ctx = chart.ctx;
          const xAxis = chart.scales['x-axis-1'];
          const yAxis = chart.scales['y-axis-1'];
          const tickGap = xAxis.getPixelForTick(1) - xAxis.getPixelForTick(0);

          ctx.save();
          let previousDate = null;
          let currentBackgroundColor = 'rgba(255, 255, 255, 0)';
          xAxis.ticks.forEach((tick, index) => {
            if (index === 0) {
              previousDate = tick.split(' ')[1];

              return;
            }

            const currentDate = tick.split(' ')[1];
            const sameDay = previousDate === currentDate;
            previousDate = currentDate;

            if (!sameDay) {
              currentBackgroundColor = currentBackgroundColor === 'rgba(255, 255, 255, 0)'
                ? 'rgba(230, 246, 236, 1)'
                : 'rgba(255, 255, 255, 0)';
            }

            const startX = xAxis.getPixelForTick(index - 1) + tickGap / 2;
            const endTickGap = tickGap * 0.5;
            const endX = xAxis.getPixelForTick(index) + endTickGap;

            ctx.fillStyle = currentBackgroundColor;
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillRect(startX, yAxis.top, endX - startX, yAxis.bottom - yAxis.top);
          });
          ctx.restore();
        }
      });
    }
  }
};
</script>
